import Vue from 'vue'
//import VueGtm from 'vue-gtm'
//import VueGtm from '@gtm-support/vue2-gtm';
import { useGtm } from '@gtm-support/vue2-gtm';
import { Store } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers'
import { ecommerce } from 'vue-analytics';
import itemActions from 'core/modules/cart/store/actions/itemActions';


export const isEnabled = (gtmId: string | null) => {
  return typeof gtmId === 'string' && gtmId.length > 0 && !isServer
}

export function afterRegistration (config, store: Store<any>) {
  if (isEnabled(config.googleTagManager.id)) {
    //const GTM = (Vue as any).gtm
    const GTM = useGtm();
    const storeView = currentStoreView()
    const currencyCode = storeView.i18n.currencyCode

    const getProductGA4 = (item): any => {
    /**
    * 
      item_name: 'Finnish magical parka',
      item_id: 'mp1122',
      price: '31.10',
      item_brand: 'PARKA4LIFE',
      item_category: 'Apparel',
      item_category2: 'Coats',
      item_category3: 'Parkas',
      item_category4: 'Unisex',
      item_variant: 'Navy blue',
      quantity: '3'
    */
      const { name: item_name, id, sku : item_id, price_incl_tax: price, category, qty: quantity, brand_name: item_brand } = item
      let product = {
        item_name,
        item_id,
        price,
        item_brand
      }
      if (quantity) {
        product['quantity'] = quantity
      }

      if (category && category.length > 0) {
        product['item_category'] = category.slice(-1)[0].name
      }

      return product
    }

    const getProduct = (item): any => {
      const { name, id, sku, price_incl_tax: price, category, qty: quantity, brand_name: brand } = item
      let product = {
        name,
        id,
        sku,
        price,
        brand

        
      }
      if (quantity) {
        product['quantity'] = quantity
      }

      if (category && category.length > 0) {
        product['category'] = category.slice(-1)[0].name
      }

      return product
    }

    store.subscribe(({ type, payload }, state) => {
      // Adding a Product to a Shopping Cart
      if (type === 'cart/cart/ADD') {
        GTM.trackEvent({
          event: 'addToCart',
          ecommerce: {
            currencyCode: currencyCode,
            add: {
              products: [getProduct(payload.product)]
            }
          }
        });
      }

      // Removing a Product from a Shopping Cart
      if (type === 'cart/cart/DEL') {
        GTM.trackEvent({
          event: 'removeFromCart',
          ecommerce: {
            remove: {
              products: [getProduct(payload.product)]
            }
          }
        });
      }

      // Measuring Views of Product Details
      if (type === 'product/product/SET_CURRENT') {
        const prd = getProductGA4(payload)
        const v = `${parseFloat(prd.price)}`
        GTM.trackEvent({
          'ecommerce': {            
            'view_item': {
              'currency': 'PLN',
              'value': v,                            
              'items': [prd]            
            }
          },
          'value': v, 
        });
      }

      // Measuring Purchases
      if (type === 'order/orders/LAST_ORDER_CONFIRMATION') {        
        const cartId = payload.order.cart_id
        const token = payload.order.user_id
        const orderId = payload.confirmation.backendOrderId
        const orderedProducts = payload.order.products.map(product => getProduct(product))
        const orderedProductsGA4 = payload.order.products.map(product => getProductGA4(product))
        const apiUrl = config.api.url;

        fetch(`${apiUrl}/api/cart/totals?cartId=${cartId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json()).then(json => {
          if (json && json.result) {            
              const v = `${parseFloat(json.result.revenue)}`
              const t = `${parseFloat(json.result.tax).toFixed(2)}`
              const s =  `${json.result.shipping}`               
              GTM.trackEvent({
                'ecommerce': {
                  'purchase': {
                    currency: 'PLN',
                    value: v,
                    tax: t,
                    shipping: s,
                    affiliation: window.location.hostname,
                    transaction_id: orderId,
                    coupon: '',
                    items: orderedProductsGA4
                  }
                }, 
                'value': v          
              })                    
            
          }
        })
      }
    })
  }
}

