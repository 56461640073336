import { GetterTree } from 'vuex'
import CmsBlockState from '../../types/CmsBlockState'
import RootState from '@vue-storefront/core/types/RootState'

const getters: GetterTree<CmsBlockState, RootState> = {
 
  // @deprecated
  // cmsBlocks: (state, getters) => getters.getCmsBlocks,
  // @deprecated
  // cmsBlockIdentifier: (state, getters) => (identifier) => getters.getCmsBlockByIdentifier(identifier),
  // @deprecated
  // cmsBlockId: (state, getters) => (id) => getters.getCmsBlockById(id),
  getCmsBlockByIdentifier: (state) => (identifier) =>
    state.items.find(item => typeof item === 'object' && item.identifier === identifier),
  getCmsBlockById: (state) => (id) => state.items.find(item => item.id === id),
  getCmsBlocks: (state) => state.items,
  hasItems: (state) => state.items && state.items.length > 0,
  findCmsBlocks: (state) => ({ key, value }) => state.items.filter(item => item[key] === value),
  cmsBlocks: (state) => state.items,
  cmsBlockIdentifier: (state) => (identifier) => {
    if (state.items.length > 0) {
      return state.items.find(item => {
        if (typeof item !== 'undefined' && item.identifier === identifier) {
          return item;
        } else {
          return false;
        }
      })
    } else {
      return false;
    }
  },
  cmsBlockId: (state) => (id) => {
    return state.items.find(item => item.id === id)
  }
 
}

export default getters
