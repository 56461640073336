import { createModule } from '@vue-storefront/core/lib/module'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'

const KEY = 'stationarystore'
const _store = {
  namespaced: true,
  state: {
    selectedStore: {}
  },
  mutations: {
    setSelectedStore (state, store) {
      state.selectedStore = store;
    }
  }
}
// export const StationaryStore = createModule({
// key: KEY,
// store: { modules: [{ key: KEY, module: store }] }
// })

export const StationaryStore: StorefrontModule = function ({ store, router }) {
  store.registerModule(KEY, _store)
}
