import { createModule } from '@vue-storefront/core/lib/module'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
const KEY = 'categoryhomepage'
const _store = {
  namespaced: true,
  state: {
    new_products: [],
    promotions: [],
    popular_products: [],
    blogs: []
  }
}

/*export const CategoryHomePage = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module: store }] }
})*/

export const CategoryHomePage: StorefrontModule = function ({ store, router }) {
  store.registerModule(KEY, _store)
}