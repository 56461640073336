import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { DataResolver } from './types/DataResolver'
import Task from '@vue-storefront/core/lib/sync/types/Task'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import config from 'config';

const headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  }
const findDeliveryPoints = async (data: any):  Promise<Task> =>
{
    const postcode = data.postcode;
    const distance = data.distance;

    return TaskQueue.execute({
        url: processLocalizedURLAddress(buildInpostUrl(postcode, distance)),
        payload: {
            method: 'GET',
            mode: 'cors',
            headers
        }
    })
}

const buildInpostUrl = (postcode: string, distance: number) : string => {
    let url = `${config.api.url}/api/inpost/pointsInDistance?post_code=${postcode}`;
    if (distance) {
        url = `${url}&max_distance=${distance}`
    }
    return url
}

export const InPostService: DataResolver.InPostService = {
    findDeliveryPoints
}