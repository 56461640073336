import { GetterTree } from 'vuex'
import sumBy from 'lodash-es/sumBy'
import CartState from '../types/CartState'
import RootState from '@vue-storefront/core/types/RootState'
import AppliedCoupon from '../types/AppliedCoupon'
import { onlineHelper, isServer, calcItemsHmac } from '@vue-storefront/core/helpers'
import { calculateTotals } from '@vue-storefront/core/modules/cart/helpers'
import config from 'config'
import i18n from '@vue-storefront/i18n'

const getters: GetterTree<CartState, RootState> = {
  /*
  getTotals: ({ cartItems, platformTotalSegments }, getters) =>
    (platformTotalSegments && onlineHelper.isOnline) ? platformTotalSegments : calculateTotals(getters.getFirstShippingMethod, getters.getFirstPaymentMethod, cartItems),
  getItemsTotalQuantity: ({ cartItems }) => config.cart.minicartCountType === 'items' ? cartItems.length : sumBy(cartItems, p => p.qty),
  getCoupon: ({ platformTotals }): AppliedCoupon | false =>
    !(platformTotals && platformTotals.hasOwnProperty('coupon_code')) ? false : { code: platformTotals.coupon_code, discount: platformTotals.discount_amount },
  */
  canSyncTotals (state, getters) {
    return getters.isTotalsSyncEnabled && getters.isCartConnected
  },
  getFirstPaymentMethod (state) {
    return state.payment instanceof Array ? state.payment[0] : state.payment
  },
  getFirstShippingMethod (state) {
    return state.shipping instanceof Array ? state.shipping[0] : state.shipping
  },
  getCoupon (state) {
    const { platformTotals } = state
    return !(platformTotals && platformTotals.hasOwnProperty('coupon_code'))
      ? false
      : { code: platformTotals.coupon_code, discount: platformTotals.discount_amount }
  },
  canUpdateMethods (state, getters) {
    return getters.isCartSyncEnabled && getters.isCartConnected
  },
  getIsMicroCartOpen (state) {
    return state.isMicrocartOpen
  },
  getIsAdding (state) {
    return state.isAddingToCart
  },
  isCartEmpty (state) {
    return state.cartItems.length === 0
  },
  bypassCounter (state) {
    return state.connectBypassCount
  },
  getPaymentMethodCode (state) {
    return state.payment && state.payment.code
  },
  getShippingMethodCode (state) {
    return state.shipping && state.shipping.method_code
  },
  isVirtualCart (state) {
    const { cartItems } = state;
    return cartItems.length ? cartItems.every(itm => itm.type_id === 'downloadable' || itm.type_id === 'virtual') : false
  },
  getCartToken (state) {
    return state.cartServerToken
  },
  getLastSyncDate (state) {
    return state.cartServerLastSyncDate
  },
  getLastTotalsSyncDate (state) {
    return state.cartServerLastTotalsSyncDate
  },
  getShippingMethod (state) {
    return state.shipping
  },
  getPaymentMethod (state) {
    return state.payment
  },
  getLastCartHash (state) {
    return state.cartItemsHash
  },
  getCurrentCartHash (state) {
    return calcItemsHmac(state.cartItems, state.cartServerToken)
  },
  isCartHashChanged (state, getters) {
    return getters.getCurrentCartHash !== state.cartItemsHash
  },
  isSyncRequired (state, getters) {
    return !state.cartItemsHash || (getters.getCurrentCartHash !== state.cartItemsHash) || !state.cartServerLastSyncDate // first load - never synced
  },
  isTotalsSyncRequired (state, getters) {
    return !state.cartItemsHash || (getters.getCurrentCartHash !== state.cartItemsHash) || !state.cartServerLastTotalsSyncDate // first load - never synced
  },
  isCartHashEmptyOrChanged (state, getters) {
    return !state.cartItemsHash || (getters.getCurrentCartHash !== state.cartItemsHash)
  },
  getCartItems (state) {
    return state.cartItems
  },
  isTotalsSyncEnabled (state) {
    return config.cart.synchronize_totals && onlineHelper.isOnline && !isServer
  },
  isCartConnected (state) {
    return !!state.cartServerToken
  },
  isCartSyncEnabled (state) {
    return config.cart.synchronize && onlineHelper.isOnline && !isServer
  },
  getTotals (state) {
    if (state.platformTotalSegments && onlineHelper.isOnline) {
      return state.platformTotalSegments
    } else {
      let shipping = state.shipping instanceof Array ? state.shipping[0] : state.shipping
      let payment = state.payment instanceof Array ? state.payment[0] : state.payment
      const totalsArray = [
        // {
        //   code: 'subtotalInclTax',
        //   title: i18n.t('Subtotal incl. tax'),
        //   value: sumBy(state.cartItems, (p) => {
        //     return p.qty * p.priceInclTax
        //   })
        // },
        {
          code: 'subtotalInclTax',
          title: i18n.t('Subtotal incl. tax'),
          value: sumBy(state.cartItems, (p) => {
            return p.qty * p.price_incl_tax
          })
        },
        // {
        //   code: 'grand_total',
        //   title: i18n.t('Grand total'),
        //   value: sumBy(state.cartItems, (p) => {
        //     return p.qty * p.priceInclTax + (shipping ? shipping.price_incl_tax : 0)
        //   })
        // }
        {
          code: 'grand_total',
          title: i18n.t('Grand total'),
          value: sumBy(state.cartItems, (p) => {
            return p.qty * p.price_incl_tax + (shipping ? shipping.price_incl_tax : 0)
          })
        }
      ]
      if (payment) {
        totalsArray.push({
          code: 'payment',
          title: i18n.t(payment.title),
          value: payment.costInclTax
        })
      }
      if (shipping) {
        totalsArray.push({
          code: 'shipping',
          title: i18n.t(shipping.method_title),
          value: shipping.price_incl_tax
        })
      }
      return totalsArray
    }
  },
  getItemsTotalQuantity (state, getters, rootStore) {
    if (config.cart.minicartCountType === 'items') {
      return state.cartItems.length
    } else if (config.cart.minicartCountType === 'quantities') {
      return sumBy(state.cartItems, p => p.qty)
    }
  }
}

export default getters
