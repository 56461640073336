import { currentStoreView } from '@vue-storefront/core/lib/multistore'

const applyCurrencySign = (formattedPrice, { currencySign, priceFormat }) => {
  return priceFormat.replace('{sign}', currencySign).replace('{amount}', formattedPrice)
};

const getLocaleSeparators = (defaultLocale) => {
  return {
    decimal: (0.01).toLocaleString(defaultLocale).replace(/[0-9]/g, ''),
    group: (1000).toLocaleString(defaultLocale).replace(/[0-9]/g, '')
  }
};

const replaceSeparators = (formattedPrice, currencySeparators, separators) => {
  if (currencySeparators.decimal) formattedPrice = formattedPrice.replace(separators.decimal, currencySeparators.decimal);
  if (currencySeparators.group) formattedPrice = formattedPrice.replace(separators.group, currencySeparators.group);
  return formattedPrice;
};

/**
 * Converts number to price string
 * @param {Number} value
 */
export function price (value, storeView) {
  if (isNaN(value)) {
    console.log('isNaN(value)')
    return value
  }
  let formattedVal = Math.abs(parseFloat(value)).toFixed(2)
  const _storeView = storeView || currentStoreView();
  // TEMP FIX
  if (!_storeView.i18n) {
    return Number(value).toFixed(2)
  }

  const prependCurrency = (price) => {
    return _storeView.i18n.currencySign + price
  }

  const appendCurrency = (price) => {
    return price + _storeView.i18n.currencySign
  }

  if (_storeView.i18n.currencySignPlacement === 'append') {
    formattedVal = formattedVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')
    formattedVal = appendCurrency(formattedVal)
    // formattedVal = Number(formattedVal).toLocaleString(`${storeView.i18n.defaultLocale}`, { style: 'currency', currency: `${storeView.i18n.currencyCode}` })
  } else {
    formattedVal = prependCurrency(formattedVal)
  }

  if (value >= 0) {
    return formattedVal
  } else {
    return '-' + formattedVal
  }
}
