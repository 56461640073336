import { CategoryService } from './CategoryService'
import { UserService } from './UserService'
import { CartService } from './CartService'
import { OrderService } from './OrderService'
import { StockService } from './StockService'
import { ReviewsService } from './ReviewsService'
import { NewsletterService } from './NewsletterService'
import { InPostService } from './InPostService'
export {
  CategoryService,
  UserService,
  CartService,
  OrderService,
  StockService,
  ReviewsService,
  NewsletterService,
  InPostService
}
