import Vue from 'vue'
//import VueGtm from 'vue-gtm'
import VueGtm from '@gtm-support/vue2-gtm'

import { once, isServer } from '@vue-storefront/core/helpers'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { Logger } from '@vue-storefront/core/lib/logger'

import { googleTagManagerModule } from './store'
import { afterRegistration, isEnabled } from './hooks/afterRegistration'

export const KEY = 'google-tag-manager'

export const GoogleTagManagerModule: StorefrontModule = function ({ store, router, appConfig }) {
  if (isEnabled(appConfig.googleTagManager.id)) {
    once('__VUE_EXTEND_GTM__', () => {

      let gTagId = appConfig.googleTagManager.id
      if (typeof window.location.host !== 'undefined' && appConfig.googleTagManager.ids !== 'undefined') {
        const host = window.location.host
        const gTagIds = appConfig.googleTagManager.ids
        for (var elem in gTagIds) {
          if (host.indexOf(elem) > -1) {
            gTagId = gTagIds[elem]
          }
        }
      }

      Vue.use(VueGtm, {
        enabled: true,
        id: gTagId,
        debug: appConfig.googleTagManager.debug,
        vueRouter: router
      })
    })
  } else {
    Logger.warn('Google Tag Manager extensions is not working. Ensure Google Tag Manager container ID is defined in config', 'GTM')()
  }

  store.registerModule(KEY, googleTagManagerModule)

  afterRegistration(appConfig, store)
}
