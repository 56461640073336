// This function will be executed before entering each route.
// It's important to have 'next()'. It enables navigation to new route.
// See https://router.vuejs.org/guide/advanced/navigation-guards.html#global-guards
import { Route } from 'vue-router'
import store from '@vue-storefront/core/store'
import { Logger } from '@vue-storefront/core/lib/logger'
import { processDynamicRoute, normalizeUrlPath } from '../helpers'
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'
import { LocalizedRoute } from '@vue-storefront/core/lib/types'
import { RouterManager } from '@vue-storefront/core/lib/router-manager'
import { routerHelper } from '@vue-storefront/core/helpers'
import { isServer } from '@vue-storefront/core/helpers'
import Vue from 'vue'
import config from 'config'
export const UrlDispatchMapper = async (to) => {
  const routeData = await store.dispatch('url/mapUrl', { url: to.path, query: to.query })
  return Object.assign({}, to, routeData)
}


// helpers for redirecting to archived products
const getEanFromUrlPath = (url: string): string | null => {
  const regex = /(?:\D)(\d+)(?=\.htm)/
  return url.match(regex) ? url.match(regex)[1] : null
}

const getProductByEan = async (ean: string): Promise<Record<string, any> | null> => {
  const product = await store.dispatch('product/single', { options: { ean }, setCurrentProduct: false, selectDefaultVariant: false, key: 'ean' })
  return product
}

const handleRedirects = (next, route = '/page-not-found'): void => {
  if (!isServer) {
    next(localizedRoute(route, currentStoreView().storeCode))
  } else {
    const storeCode = currentStoreView().storeCode
    const baseUrl = config.baseUrl ? config.baseUrl : '/'
    Vue.prototype.$ssrRequestContext.server.response.redirect((storeCode !== '' ? + '/' + storeCode + baseUrl : baseUrl) + route) // TODO: Refactor this one after @filrak will give us a way to access ServerContext from Modules directly :-)
    // ps. we can't use the next() call here as it's not doing the real redirect in SSR mode (just processing different component without changing the URL and that causes the CSR / SSR DOM mismatch while hydrating)
  }
}

export async function beforeEachGuard (to: Route, from: Route, next) {

  if (RouterManager.isRouteProcessing()) {
    await RouterManager.getRouteLockPromise()
    next()
    return
  }
  RouterManager.lockRoute()

  const path = normalizeUrlPath(to.path)
  const hasRouteParams = to.hasOwnProperty('params') && Object.values(to.params).length > 0
  const isPreviouslyDispatchedDynamicRoute = to.matched.length > 0 && to.name && to.name.startsWith('urldispatcher')
  if (!to.matched.length || (isPreviouslyDispatchedDynamicRoute && !hasRouteParams)) {
    UrlDispatchMapper(to)
      .then((routeData) => {
        if (routeData) {          
          let dynamicRoutes:  LocalizedRoute[] = []
          dynamicRoutes.push(processDynamicRoute(routeData, path, !isPreviouslyDispatchedDynamicRoute))
          if (dynamicRoutes && dynamicRoutes.length > 0) {
            next({
              ...dynamicRoutes[0],
              replace: routerHelper.popStateDetected || dynamicRoutes[0].fullPath === from.fullPath
            })
          } else {
            Logger.error('Route not found ' + routeData['name'], 'dispatcher')()
            next(localizedRoute('/page-not-found', currentStoreView().storeCode))
          }
        } else {
          Logger.error('No mapping found for ' + path, 'dispatcher')()
          next(localizedRoute('/page-not-found', currentStoreView().storeCode))
        }
      })
      .catch((e) => {
        Logger.error(e, 'dispatcher')()
        // redirect to product page if product found by ean extracted from url
        if (getEanFromUrlPath(path)) {
          const ean = getEanFromUrlPath(path)
          getProductByEan(ean)
            .then((product) => {
              handleRedirects(next, `/${product.url_path}`)
            })
            .catch((e) => {
              Logger.error(e, 'dispatcher')()
              handleRedirects(next)
            })
        } else {
          handleRedirects(next)
        }
      })
      .finally(() => {
        routerHelper.popStateDetected = false
        RouterManager.unlockRoute()
      })
  } else {
    next()
    RouterManager.unlockRoute()
    routerHelper.popStateDetected = false
  }

  routerHelper.popStateDetected = false
}
