import { StorefrontModule } from '@vue-storefront/core/lib/modules'

interface State {
  addToCartLoader: boolean;
  sidebar: boolean;
  microcart: boolean;
  wishlist: boolean;
  searchpanel: boolean;
  newsletterPopup: boolean;
  overlay: boolean;
  loader: boolean;
  authElem: string;
  checkoutMode: boolean;
  openMyAccount: boolean;
  autoSearchSKU: boolean;
  stationaryStoreInfoPanel: boolean;
  availabilityPanel: boolean;
  submenu: {
    depth: boolean;
    path: [];
    };
};

interface Mutations {
  setAddToCartLoader (state, action): void;
  setCheckoutMode (state, action): void;
  setMicrocart (state, action): void; 
  setSidebar (state, action): void; 
  setSubmenu (state, { id, depth }): void
  setSearchpanel (state, action): void;
  setAvailabilityPanel (state, action): void;
  setAutoSearchSKU (state, action): void;
  setStationaryStoreInfoPanel (state, action): void;
  setWishlist (state, action): void; 
  setOverlay (state, action): void; 
  setLoader (state, action): void; 
  setAuthElem (state, action): void;
};
interface Actions {
  toggleMicrocart ({ commit, state }): void;
  toggleWishlist ({ commit, state }): void;
  checkProductAvailability ({ commit, state }, action): void;
  closeMicrocart ({ commit, state }): void;
  closeWishlist ({ commit, state }): void;
}

interface UiStore {
  namespaced: boolean;
  state: State;
  mutations: Mutations;
  actions: Actions;
};

const KEY: string = 'ui'
const _store: UiStore = {
  namespaced: true,
  state: {
    addToCartLoader: false,
    sidebar: false,
    microcart: false,
    wishlist: false,
    searchpanel: false,
    newsletterPopup: false,
    overlay: false,
    loader: false,
    authElem: 'login',
    checkoutMode: false,
    openMyAccount: false,
    availabilityPanel: false,
    autoSearchSKU: false,
    stationaryStoreInfoPanel: false,
    submenu: {
      depth: false,
      path: []
    }
  },
  mutations: {
    setAddToCartLoader (state, action) {
      state.addToCartLoader = action === true
    },
    setCheckoutMode (state, action) {
      state.checkoutMode = action === true
    },
    setMicrocart (state, action) {
      state.microcart = action === true
      state.overlay = action === true
    },
    setSidebar (state, action) {
      state.sidebar = action === true
      state.overlay = action === true
    },
    setSubmenu (state, { id, depth }) {
      if (id) {
        state.submenu.path.push(id)
      } else if (state.submenu.path.length) {
        setTimeout(() => {
          state.submenu.path.pop()
        }, 300)
      }
      state.submenu.depth = state.submenu.depth > 0 && depth
    },
    setSearchpanel (state, action) {
      state.searchpanel = action === true
      state.overlay = action === true
    },
    setAvailabilityPanel (state, action) {
      state.availabilityPanel = action === true
      state.overlay = action === true
    },
    setAutoSearchSKU (state, action) {
      state.autoSearchSKU = action === true
    },
    setStationaryStoreInfoPanel (state, action) {
      state.stationaryStoreInfoPanel = action === true
      state.overlay = action === true
    },
    setWishlist (state, action) {
      state.wishlist = action === true
      state.overlay = action === true
    },
    setOverlay (state, action) {
      state.overlay = action === true
    },
    setLoader (state, action) {
      state.loader = action === true
    },
    setAuthElem (state, action) {
      state.authElem = action
    }
  },
  actions: {
    toggleMicrocart ({ commit, state }) {
      commit('setMicrocart', !state.microcart)
    },
    toggleWishlist ({ commit, state }) {
      commit('setWishlist', !state.wishlist)
    },
    checkProductAvailability ({ commit, state }, action) {
      commit('setAutoSearchSKU', action)
      commit('setAvailabilityPanel', !state.availabilityPanel)
    },
    closeMicrocart ({ commit, state }) {
      if (state.microcart) commit('setMicrocart', false)
    },
    closeWishlist ({ commit, state }) {
      if (state.wishlist) commit('setWishlist', false)
    }
  }
}

export const Ui: StorefrontModule | undefined | any = function ({ store, router }) {
  store.registerModule(KEY, _store)
}