import { ActionTree } from 'vuex'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState';
import CmsBlockState from '../../types/CmsBlockState'
import { createLoadingBlockQuery, createSingleBlockQuery } from '@vue-storefront/core/modules/cms/helpers'
import { SearchQuery } from 'storefront-query-builder'
import { Logger } from '@vue-storefront/core/lib/logger'

const actions: ActionTree<CmsBlockState, RootState> = {
  /**
   * Retrieve cms blocks
   *
   * @param context
   * @param {any} filterValues
   * @param {any} filterField
   * @param {any} size
   * @param {any} start
   * @param {any} excludeFields
   * @param {any} includeFields
   * @returns {Promise<T> & Promise<any>}
   */
  list (context, { filterValues = null, filterField = 'identifier', size = 150, start = 0, excludeFields = null, includeFields = null, skipCache = false }) {
    let query = new SearchQuery()
    if (filterValues) {
      query = query.applyFilter({ key: filterField, value: { 'like': filterValues } })
    }
    if (skipCache || (!context.state.items || context.state.items.length === 0)) {
      //TODO - if we have more then 150 chunks we won't load all chunks
      return quickSearchByQuery({ query, entityType: 'cms_block', size, excludeFields, includeFields })
        .then((resp) => {
          context.commit(types.CMS_BLOCK_UPDATE_CMS_BLOCKS, resp.items)
          return resp.items
        })
        .catch(err => {
          Logger.error(err, 'cms')()
        })
    } else {
      return new Promise((resolve, reject) => {
        let resp = context.state.items
        resolve(resp)
      })
    }
  },
  single (context, { key = 'identifier', value, excludeFields = null, includeFields = null, skipCache = false }) {
    const state = context.state
    if (skipCache || !state.items.find((itm) => {
      if (typeof itm !== 'undefined') {
        return itm[key] === value
      }
      return false;
    })) {
      let query = new SearchQuery()
      if (value) {
        query = query.applyFilter({ key: key, value: { 'like': value } })
      }
      const t0 = new Date().getTime()
      return quickSearchByQuery({ query, entityType: 'cms_block', excludeFields, includeFields })
        .then((resp) => {
          if (resp && resp.items && resp.items[0]) {
            const t1 = new Date().getTime()
            // console.log('cms success === ', query, key, resp, t1 - t0);
            context.commit(types.CMS_BLOCK_ADD_CMS_BLOCK, resp.items[0])
            // console.log('cms after commit');
          }
          return resp.items[0]
        })
        .catch(err => {
          console.log('cms error === ', query, key, err);
          Logger.error(err, 'cms')()
        })
    } else {
      return new Promise((resolve, reject) => {
        if (state.items.length > 0) {
          let cmsBlock = state.items.find((itm) => {
            if (typeof itm !== 'undefined') {
              return itm[key] === value
            }
            return false;
          });
          if (cmsBlock) {
            resolve(cmsBlock)
          } else {
            reject(new Error('CMS block query returned empty result ' + key + ' = ' + value))
          }
        } else {
          resolve()
        }
      })
    }
  },
  addItem ({ commit }, block) {
    commit(types.CMS_BLOCK_ADD_CMS_BLOCK, block)
  }
}

export default actions
