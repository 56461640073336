import config from 'config'
import Product from '@vue-storefront/core/modules/catalog/types/Product'
import { ProductConfiguration, ProductOption } from '@vue-storefront/core/modules/catalog/types/ProductConfiguration'

export const getSystemFilterNames: string[] = config.products.systemFilterNames

const getAvailableFiltersByProduct = (product: Product) => {
  let filtersMap = {}
  if (product && product.configurable_options) {
    product.configurable_options.forEach(configurableOption => {
      const type = configurableOption.attribute_code
      const filterVariants = configurableOption.values.map(({ value_index, label }) => {
        return { id: value_index, label, type }
      })
      filtersMap[type] = filterVariants
    })
  }
  return filtersMap
}

const getSelectedFiltersByProduct = (product: Product, configuration: ProductConfiguration) => {
  if (!configuration) {
    return null
  }

  let selectedFilters = {}
  if (configuration && product) {
    Object.keys(configuration).map(filterType => {
      const filter = configuration[filterType]
      selectedFilters[filterType] = {
        id: filter.id,
        label: filter.label,
        type: filterType
      }
    })
  }
  return selectedFilters
}

const getFilterQuery = ({ currentQuery = {}, filterVariant }: {currentQuery?: any, filterVariant?: (ProductOption | ProductOption[])} = {}) => {
  const newQuery = JSON.parse(JSON.stringify(currentQuery))
  console.log("new query ===", newQuery, filterVariant)
  if (!filterVariant || (filterVariant instanceof Array && filterVariant.length === 0)) return newQuery
  if (filterVariant instanceof Array) {
    let queryFilter = []
    filterVariant.forEach((variant: ProductOption) => {
      queryFilter.push(variant.id)
    })
    // delete or add filter variant to query
    if (!queryFilter.length) delete newQuery[filterVariant[0].attribute_code]
    else newQuery[filterVariant[0].attribute_code] = queryFilter
  } else {
    if (getSystemFilterNames.includes(filterVariant.attribute_code)) {
      if (newQuery[filterVariant.attribute_code] && newQuery[filterVariant.attribute_code] === filterVariant.id) {
        delete newQuery[filterVariant.attribute_code]
      } else {
        newQuery[filterVariant.attribute_code] = filterVariant.id
      }
    } else {
      let queryFilter = newQuery[filterVariant.attribute_code] || []
      if (!Array.isArray(queryFilter)) queryFilter = [queryFilter]
      queryFilter = [filterVariant.id]
      // delete or add filter variant to query
      if (!queryFilter.length) delete newQuery[filterVariant.attribute_code]
      else newQuery[filterVariant.attribute_code] = queryFilter
    }
  }
  return newQuery
}

export const getFiltersFromQuery = ({ filtersQuery = {} } = {}): any => {
  const searchQuery = {}
  Object.keys(filtersQuery).forEach(filterKey => {
    let queryValue = filtersQuery[filterKey]
    if (getSystemFilterNames.includes(filterKey)) {
      if (typeof queryValue !== 'string' && filtersQuery[filterKey].length > 1) {
        searchQuery[filterKey] = filtersQuery[filterKey].map(qv => ({
          id: qv,
          label: qv,
          attribute_code: filterKey
        }))
      } else {
        searchQuery[filterKey] = {
          id: typeof queryValue === 'string' ? queryValue : queryValue[0],
          label: typeof queryValue === 'string' ? queryValue : queryValue[0],
          attribute_code: filterKey
        }
      }
    } else {
      if (typeof queryValue !== 'string' && filtersQuery[filterKey].length > 1) {
        searchQuery[filterKey] = filtersQuery[filterKey].map(qv => ({
          id: qv,
          label: qv,
          attribute_code: filterKey
        }))
      } else {
        searchQuery[filterKey] = {
          id: typeof queryValue === 'string' ? queryValue : queryValue[0],
          label: typeof queryValue === 'string' ? queryValue : queryValue[0],
          attribute_code: filterKey
        }
      }
    }
  })
  return searchQuery
}

export { getAvailableFiltersByProduct, getSelectedFiltersByProduct, getFilterQuery }
